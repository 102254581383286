import React from 'react';

const ArrowRightIcon = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.92505 14.6L7.35838 9.16666C8.00005 8.52499 8.00005 7.47499 7.35838 6.83333L1.92505 1.39999" stroke={"currentColor"} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowRightIcon